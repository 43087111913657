import React from 'react'
import TranslateText from 'components/TranslateText'
import useLang from 'lib/hooks/useLang'
import Messages from 'translations/Message'
import { Div } from 'components/ui'


const CopyComponent = () => {

  const { lang } = useLang()

  return (
    <div>
      <Div className='bg-shapes bg-cover py-36 px-8 h-full text-center text-black-melody'
        smScreen='py-56'
        lgScreen='ml-2 text-5xl'
      >
        <h2 className='py-4 text-4xl font-bold lg:text-5xl'>
          <TranslateText lang={lang} msg={Messages.copy} />
        </h2>
        <p className='mt-10 sm:mt-6 lg:mt-10 text-3xl leading-snug'>
          <TranslateText lang={lang} msg={Messages.copyH3} />
        </p>
      </Div>
    </div>
  )
}

export default CopyComponent
