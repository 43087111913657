import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import TranslateText from 'components/TranslateText'
import useLang from 'lib/hooks/useLang'
import Messages from 'translations/Message'
import { Div } from 'components/ui'


const AboutComponent = () => {

  const { lang } = useLang()

  return (
    <Div className='bg-gray-200 text-center px-6 pt-12 pb-10'
      lgScreen='px-0 py-28'
    >
      <Div className='mx-auto'
        mdScreen='max-w-5xl'
        lgScreen='max-w-screen-md'
      >
        <h2 className='-mb-8 lg:mb-12 text-2xl sm:text-3xl font-semibold'>
          <TranslateText lang={lang} msg={Messages.aidasTitle} />
        </h2>
        <div className='functionLayout flex-row-reverse'>
          <div className='lg:px-8 items-center justify-center lg:w-1/2'>
            <h3 className='sm:max-w-2xl mx-auto'>
              <TranslateText lang={lang} msg={Messages.aidasHiddenTitle} />
            </h3>
            <p className='functionDetails'>
              <TranslateText lang={lang} msg={Messages.aidasDesc} />
            </p>
          </div>
          <div className='lg:flex lg:max-w-5xl lg:mx-auto lg:pb-6 w-full h-full lg:w-1/2'>
            <StaticImage src='../../images/welcome/aidas_model.svg'
                alt='About app function2'
                placeholder='blurred'
                width={400}
                height={400} />
          </div>
        </div>
      </Div>
    </Div>
  )
}

export default AboutComponent
