import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { FaFacebook } from 'react-icons/fa'
import pkg from '../../../package.json'
import TranslateText from 'components/TranslateText'
import useLang from 'lib/hooks/useLang'
import Messages from 'translations/Message'
import { Div } from 'components/ui'


const FooterComponent = () => {

  const { lang } = useLang()

  return (
    <Div id='footer' className='bg-gray-pale text-center px-4 pt-8 pb-10 text-white text-sm text-opacity-90'
      smScreen='text-xl'
      lgScreen='px-20 pt-24 text-2xl'
    >
      <Div className='flex items-center flex-col-reverse max-w-6xl mx-auto'
        lgScreen='justify-around flex-row max-w-7xl'
      >
        <div className='flex text-left lg:items-center justify-center'>
          <div className='w-28 h-28 pl-2 lg:w-32 lg:h-32'>
            <StaticImage src='../../images/hp/logoWhite.svg'
              alt='White 1f8 logo'
              placeholder='blurred'
              width={60}
              height={50} />
          </div>
          <div className='pl-6 md:pl-10 leading-relaxed pr-6 '>
            <p><TranslateText lang={lang} msg={Messages.footerCompany} />/ 1F8</p>
            <p><TranslateText lang={lang} msg={Messages.footerSetupDate} /></p>
            <p><TranslateText lang={lang} msg={Messages.footerAdress} /></p>
            <p> US Address / 20687 Amar Road Ste 2-900, Walnut, CA 91789</p>
            <p><TranslateText lang={lang} msg={Messages.footerWebsite} /><a href='http://1f8.co.jp/'>http://1f8.co.jp/</a></p>
          </div>
        </div>
        <div className='flex justify-center pb-5 items-center'>
          <a href='https://twitter.com/1f816'>
            <div className='flex items-center w-20 h-20 mr-4 sm:w-28 sm:h-28 lg:w-32 lg:h-32'>
              <StaticImage src='../../images/hp/twitter.svg'
                alt='twitter'
                placeholder='blurred'
                width={60}
                height={50} />
              <p className='pl-1 sm:pl-3'>Twitter</p>
            </div>
          </a>
          <a href='https://blog.1f8.dev/'>
            <div className='flex pl-3 sm:pl-0 items-center w-28 h-28 sm:w-[76px] sm:h-[76px] pr-1 lg:w-36 lg:h-36'>
              <StaticImage src='../../images/hp/Vector.svg'
                alt='1f8 blog link'
                placeholder='blurred'
                width={50}
                height={65} />
              <p className='pl-1 sm:pl-1 whitespace-nowrap'>1F8 Blog</p>
            </div>
          </a>
          <a href='https://www.facebook.com/1F8dev'>
            <div className='flex pl-3 sm:pl-6 items-center'>
              <FaFacebook className='text-blue-400 w-6 h-6 sm:w-9 sm:h-9' />
              <p className='pl-1 sm:pl-3'>Facebook</p>
            </div>
          </a>
        </div>
      </Div>
      <div className='pt-12 lg:pt-20 pb-6'>
        <p>Copyright © 1F8 2021 V{pkg.version}</p>
      </div>
    </Div>
  )
}

export default FooterComponent
