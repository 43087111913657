import React from 'react'
import { navigate } from 'gatsby'
import TranslateText from 'components/TranslateText'
import useLang from 'lib/hooks/useLang'
import Messages from 'translations/Message'
import { Div } from 'components/ui'
import HpButton from './HpButton'


const AboutComponent = () => {

  const { lang } = useLang()

  return (
    <Div id='service'
      className='justify-center items-center bg-white text-center px-3 pt-16 pb-10 py-32'
      lgScreen='px-0 py-36 flex gap-10'
      xlScreen='gap-16'
    >
      <div className='lg:px-20 items-center justify-center px-24'>
        <h2 className='lg:w-full whitespace-pre-wrap sm:whitespace-normal leading-tight text-5xl font-bold'>
          <TranslateText lang={lang} msg={Messages.videoTitle} />
        </h2>
        <p className='pt-6 text-left sm:text-center text-gray-pale tracking-wide pb-6 lg:pb-10 lg:text-2xl'><TranslateText lang={lang} msg={Messages.videoDesc} /></p>
        <HpButton onClick={() => navigate('/demo')}>
          <TranslateText lang={lang} msg={Messages.tryDemo} />
        </HpButton>
      </div>
      <div className='lg:pb-6 pt-20 lg:pt-6 px-4 pb-6 flex justify-center lg:w-1/3 lg:h-96 xl:w-1/4'>
        <iframe className='sm:w-4/5 lg:w-full lg:h-full'
          width='560' height='315'
          src='https://www.youtube.com/embed/hxIi5F--G_Y'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        >
        </iframe>
      </div>
    </Div>
  )
}

export default AboutComponent
