import React from 'react'

const TranslateTextComponent = ({ lang, msg }) => {
  return (
    <>
      {msg[lang] || msg.EN || msg.JA}
    </>
  )
}

export default TranslateTextComponent
