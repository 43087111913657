import * as React from 'react'
import TranslateText from 'components/TranslateText'

const HpCardComponent = ({ children, lang, functionTitle, functionDesc,imgPosition }) => {
  return (
    <div className={`functionLayout ${imgPosition==='right' ? '' : 'flex-row-reverse'}`}>
      <div className='lg:w-1/2 max-w-[39.9rem] sm:max-w-[61rem] mx-auto'>
        <h3 className='mx-auto sm:text-3xl sm:px-44 text-left lg:px-0'><TranslateText lang={lang} msg={functionTitle} /></h3>
        <p className='functionDetails'><TranslateText lang={lang} msg={functionDesc} /></p>
      </div>
      <div className='lg:flex lg:max-w-5xl lg:mx-auto lg:pb-6 w-full h-full lg:w-1/2'>
        {children}
      </div>
    </div>
  )
}

export default HpCardComponent
