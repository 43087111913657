import React from 'react'
import 'css/homepage.css'

import Header from 'components/homepage/Header'
import Home from 'components/homepage/Home'
import Copy from 'components/homepage/Copy'
import AboutAidas from 'components/homepage/AboutAidas'
import Video from 'components/homepage/Video'
import AboutApp from 'components/homepage/AboutApp'
import Choice from 'components/homepage/Choice'
import FinalCTA from 'components/homepage/FinalCTA'
import Footer from 'components/homepage/Footer'
import Layout from 'components/Layout'


const IndexPage = () => {
  return (
    <Layout>
      <section className='index-page'>
        <Header />
        <Home />
        <Copy />
        <AboutAidas />
        <Video />
        <AboutApp />
        <Choice />
        <FinalCTA />
        <Footer />
      </section>
    </Layout>
  )
}

export default IndexPage
