const HomepageMessages = {
  functions: {
    EN: 'Functions',
    JA: '機能紹介',
  },
  price: {
    EN: 'Dropshipping',
    JA: '料金',
  },
  functionality: {
    title1: {
      EN: 'Visualize Shopify and various data in a graph based on the AIDAS model',
      JA: 'AIDASモデルに基づいてShopifyと各種プラットフォームのデータをグラフで可視化',
    },
    title2: {
      EN: 'Easy integration to various platforms such as Facebook, Instagram, Google Ads, etc.',
      JA: 'FacebookやInstagram、Google広告など各種プラットフォームへの連携も簡単！',
    },
    title3: {
      EN: 'Lots of info on the AIDAS model of web marketing!',
      JA: 'AIDASモデルのWEBマーケティングの情報が豊富！',
    },
    desc1: {
      EN: 'Start by Copy_Pasting a sample of your data from CSV/Excel. You will be able to preview your order and make edits before uploading.',
      JA: '各種SNS・ネット広告で提供されているデータをAIDASモデルに従って分析することで、具体的なアクションが取りやすくなります。',
    },
    desc2: {
      EN: 'Easy integration to various platforms such as Facebook, Instagram, Google Ads, etc.',
      JA: `- Shopify
- Facebook
- Instagram
- Google広告
- Yahoo広告
- Googleアナリティクス

に対応しています。`,
    },
    desc3: {
      EN: 'I\'m sick and tired of all the terminology required for the AIDAS model of web marketing, such as CPC, ROAS, etc... We provide a glossary of terms and videos for those who are fed up with the many terms required when conducting AIDAS model web marketing, such as CPC and ROAS.',
      JA: 'CPCやROASなど、AIDASモデルのWEBマーケティングを行う際に必要な用語が多くてうんざり...という方に、用語集や動画を提供しています。',
    },
  },
}

export default HomepageMessages
