import React from 'react'
import TranslateText from 'components/TranslateText'
import useLang from 'lib/hooks/useLang'
import Messages from 'translations/Message'
import HpButtonComponent from './HpButton'
import { Div } from 'components/ui'


const FinalCTAComponent = () => {

  const { lang } = useLang()

  return (
    <Div id='service'
      className='bg-green-header text-center px-3 pt-8 pb-10 py-20 justify-center items-center'
      lgScreen='py-4 flex px-0'
      xlScreen='py-24'
    >
      <div className='items-center justify-around lg:px-28 lg:flex gap-10 py-20 lg:gap-32'>
        <h2 className='pb-16 lg:pb-0 text-4xl'>
          <TranslateText lang={lang} msg={Messages.startTitle} />
        </h2>
        <HpButtonComponent>
          <TranslateText lang={lang} msg={Messages.comingSoon} />
        </HpButtonComponent>
      </div>
    </Div>
  )
}

export default FinalCTAComponent
