import React from 'react'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import pkg from '../../../package.json'
import TranslateText from 'components/TranslateText'
import useLang from 'lib/hooks/useLang'
import Messages from 'translations/Message'
import { Div } from 'components/ui'


const HomeComponent = () => {

  const { lang } = useLang()

  return (
    <Div className='bg-white py-8 h-full'
      smScreen='pt-10 py-0'
      lgScreen='pt-0'
    >
      <Div id='home'
        className='bg-no-repeat'
        smScreen='h-[54rem]'
        lgScreen='mx-auto pt-2 bg-contain h-101 bg-center max-w-screen-lg'
        xlScreen='max-w-screen-xl'
      >
        <div className='pt-16 lg:px-24'>
          <h1 className={`text-left text-black-melody ml-2 font-bold text-5xl px-10 leading-normal
            sm:ml-0 sm:text-center
            lg:text-center lg:pt-10 lg:text-6xl
          `}>
            <TranslateText lang={lang} msg={Messages.homeTitle} />
          </h1>
          {/* mobile image */}
          <div className='sm:hidden w-full h-full'>
            <StaticImage src='../../images/hp/buildingsSM.svg'
              alt='small home buildings'
              placeholder='blurred'
              width={640}
              height={680}
            />
          </div>
          <Div className='z-30 text-center mt-16 mb-10'
            mdScreen='mb-10'
            smScreen='pt-99'
          >
            <button type='button'
              className='lg:text-2xl py-3 md:py-2 lg:py-4 px-10 bg-purple-button text-white font-bold rounded-xl shadow-md hover:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-opacity-75'
              onClick={() => navigate('/demo')}
            >
              <TranslateText lang={lang} msg={Messages.tryDemo} />
            </button>
          </Div>
        </div>
        {/* after sm size image */}
        <Div className='z-0 hidden mx-auto text-center'
          smScreen='block absolute left-0 right-0 top-[35rem]'
        >
          <StaticImage src='../../images/hp/building1.svg'
            alt='home buildings'
            placeholder='blurred'
            width={600}
            height={315}
          />
        </Div>
      </Div>
    </Div>
  )
}

export default HomeComponent
