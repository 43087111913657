import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import useLang from 'lib/hooks/useLang'
import TranslateText from 'components/TranslateText'
import Messages from 'translations/Message'
import { AnchorLink, Div, Link } from 'components/ui'
import HpButton from './HpButton'
import { Lang } from '../../../../shared/enums/lang'

const HeaderComponent = () => {

  const { lang, setLang } = useLang()
  const switchLang = () => setLang(_lang => _lang=== Lang.Ja ? Lang.En : Lang.Ja)

  const gLangClass = (itemLang) => itemLang===lang
    ? 'font-bold'
    : 'font-normal text-gray-300'

  return (
    <header className='mx-auto bg-green-header lg:py-5 lg:px-10'>
      {/* DESKTOP */}
      <Div className='flex justify-between items-center pt-9 px-8 pb-12'
        device='DESKTOP'
      >
        <Link href='#home' anchorLink={true}
          className='hover:no-underline font-bold text-2xl h-20 w-20 lg:text-4xl'
        >
          <StaticImage src='../../images/hp/aidas-transparent600x600.png'
            className='hover:saturate'
            width={100}
            height={100}
            alt='AIDAS Logo' />
        </Link>
        <div>
          <button onClick={switchLang} type='button'
            className='btn btn-primary'>
            <span className={gLangClass('EN')}>EN</span> | <span className={gLangClass('JA')}>JA</span>
          </button>
        </div>
      </Div>

      {/* MOBILE */}
      <Div className='mx-auto px-4 py-6 max-w-7xl'
        device='MOBILE'
        mdScreen='px-10'
        smScreen='px-6 py-10'
      >
        <div className='flex justify-between items-center'>
          <div>
            <a className='hover:no-underline font-bold text-2xl lg:text-4xl' href='#home'>
              <StaticImage src='../../images/hp/aidas-transparent600x600.png'
                className='hover:saturate h-20 w-20 lg:w-24 lg:h-24'
                width={200}
                height={200}
                alt='AIDAS Logo' />
            </a>
          </div>
          <Div className='flex mr-16 pl-6 whitespace-nowrap font-bold'
            smScreen='mr-0 pl-8'
            lgScreen='mr-16 pl-10 text-3xl'
            xlScreen='px-10'
          >
            <AnchorLink href='#functions'>
              <TranslateText lang={lang} msg={Messages.functions} />
            </AnchorLink>
            <AnchorLink href='#choice'>
              <TranslateText lang={lang} msg={Messages.featureMenu} />
            </AnchorLink>
          </Div>
          <button onClick={switchLang} type='button'
            className='font-extrabold btn btn-primary text-sm sm:text-base md:text-2xl lg:mr-6'>
            <span className={gLangClass('EN')}>EN</span> | <span className={gLangClass('JA')}>JA</span>
          </button>
          <Div className='pl-6 whitespace-nowrap'
            smScreen='pl-2'
            lgScreen='pl-6'
          >
            <Link href='mailto:hello@1f8.dev'
              className='nav-elements hover:border-blue-primary active:bg-blue-primary_pale leading-loose'
            >
              <HpButton className='min-w-[140px]'>
                <TranslateText lang={lang} msg={Messages.headerContact} />
              </HpButton>
            </Link>
          </Div>
        </div>
      </Div>
    </header>
  )
}

export default HeaderComponent
