import HomepageMessages from './homepage'

const Messages = {
  ...HomepageMessages,
  more: {
    EN: 'More details',
    JA: 'もっと見る',
  },
  achiement: {
    EN: 'Dropshipping',
    JA: '実績',
  },
  featureMenu: {
    EN: 'Features',
    JA: '特徴',
  },
  choice: {
    EN: 'Why we choose this app?',
    JA: '選ばれる理由',
  },
  choiceDesc: {
    EN: 'Are you wondering which of the many Shopify apps to choose?',
    JA: '数あるShopifyアプリの中からどれを選ぶか迷っていますか？',
  },
  videoTitle: {
    EN: `AIDAS POC 
First Steps`,
    JA: `AIDAS POC 
最初の一歩`,
  },
  videoDesc: {
    EN: 'A Video on how to set up and use the app',
    JA: 'アプリの設定方法や使い方を動画で紹介',
  },
  feature: {
    EN: 'Features of the AIDAS POC ',
    JA: 'AIDAS POC の特徴',
  },
  featureDesc1: {
    EN: `As the application is 
developed completely 
in-house, support is 
fully provided.`,
    JA: `完全自社開発の
    アプリのため、
    サポートは万全です。`,
  },
  featureDesc2: {
    EN: `Easy integration to 
    various platforms such as Facebook, Instagram, Google Ads, etc.`,
    JA: ` FacebookやInstagram、Google広告など
    各種プラットフォーム
    への連携も簡単！`,
  },
  featureDesc3: {
    EN: `Lots of info on the AIDAS model
     of web marketing!`,
    JA: `AIDASモデルの
    WEBマーケティングの情報が豊富！`,
  },
  homeTitle: {
    EN: 'For all those who want to optimize advertisings!',
    JA: '広告を最適化したいすべての人へ！',
  },
  exampleTitle: {
    EN: 'Dropshipping',
    JA: '導入事例',
  },
  exampleDesc: {
    EN: 'Dropshipping',
    JA: `Common Thread Collective 様

弊社のレポートツールを活用した起業支援活動を実施されています。`,
  },
  aboutAppTitle: {
    EN: 'About what AIDAS POC can do',
    JA: 'AIDAS POC でできること',
  },
  startTitle: {
    EN: 'Let\'s get start!',
    JA: 'さあ、始めよう',
  },
  use: {
    EN: 'Try using',
    JA: '使ってみる',
  },
  appReviewTitle: {
    EN: 'About what our Shopify app can do',
    JA: 'アプリストアのレビュー',
  },
  priceTitle: {
    EN: 'About what our Shopify app can do',
    JA: '料金プラン',
  },
  planTitle: {
    EN: 'About what our Shopify app can do',
    JA: '0-100 alerts',
  },
  planTitle2: {
    EN: 'About what our Shopify app can do',
    JA: '101-200 alerts',
  },
  qandATitle: {
    EN: 'About what our Shopify app can do',
    JA: 'よくある質問',
  },
  question1: {
    EN: 'About what our Shopify app can do',
    JA: 'Q. 問い合わせサポートは無料ですか？',
  },
  answer1: {
    EN: 'About what our Shopify app can do',
    JA: 'A. 利用者の方のサポートは無料で行っております。（Freeプランは月4回までとなります。）',
  },
  question2: {
    EN: 'About what our Shopify app can do',
    JA: 'Q. Shopify自体が初めてなのですが、アプリの導入と同時にショップを開設することはできますか？',
  },
  answer2: {
    EN: 'About what our Shopify app can do',
    JA: '  A. 弊社はShopifyサイトの制作も承っておりますので、お気軽にご相談ください。',
  },
  question3: {
    EN: 'About what our Shopify app can do',
    JA: 'Q. ドロップシッピングの仕組みがよくわからないのですが、相談することはできますか？',
  },
  answer3: {
    EN: 'About what our Shopify app can do',
    JA: 'A. はい。仕組みはもちろん、Shopifyユーザの動向をふまえた商品展開等のご相談も可能です。',
  },
  moreQuestion: {
    EN: 'About what our Shopify app can do',
    JA: 'もっと質問がありますか？',
  },
  planPrice: {
    EN: 'About what our Shopify app can do',
    JA: 'Free',
  },
  planPrice2: {
    EN: 'About what our Shopify app can do',
    JA: '990円',
  },
  achievementTitle: {
    EN: 'About what our Shopify app can do',
    JA: 'お客様に選ばれる実績',
  },
  achieveDesc1: {
    EN: 'About what our Shopify app can do',
    JA: '数値はダミーです',
  },
  reviewDesc1: {
    EN: 'About what our Shopify app can do',
    JA: `
    これはユーザのレビューを表示するエリアです。
    ユーザプロフィールと投稿時刻はダミーです。`,
  },
  userNumber1: {
    EN: 'About what our Shopify app can do',
    JA: 'ユーザー数　2人',
  },
  itemNumber1: {
    EN: 'About what our Shopify app can do',
    JA: 'アイテム数　100個',
  },
  accountNumber1: {
    EN: 'About what our Shopify app can do',
    JA: '法人アカウント　1個',
  },
  userNumber2: {
    EN: 'About what our Shopify app can do',
    JA: 'ユーザー数　10人',
  },
  itemNumber2: {
    EN: 'About what our Shopify app can do',
    JA: 'アイテム数　200個',
  },
  accountNumber2: {
    EN: 'About what our Shopify app can do',
    JA: '法人アカウント　3個',
  },
  shopConnection: {
    EN: 'About what our Shopify app can do',
    JA: '複数ショップ連携',
  },
  sampleUser: {
    EN: 'sample user',
    JA: 'sample user',
  },
  sampleTime: {
    EN: 'Yesterday 10:35AM',
    JA: 'Yesterday 10:35AM',
  },
  tryDemo: {
    EN: 'Explore Demo',
    JA: 'デモを見る',
  },
  aidasTitle: {
    EN: 'What is the AIDAS marketing model?',
    JA: 'AIDASマーケティングモデルとは？',
  },
  aidasHiddenTitle: {
    EN: '',
    JA: '',
  },
  aidasDesc: {
    EN: `Initials of

● Attention 
● Interest 
● Desire 
● Action (purchase) 
● Satisfaction 

👉When doing business or marketing, you can effectively lead customers to purchase by following this purchasing decision process.
    `,
    JA: `● Attention（注意） 
● Interest（関心） 
● Desire（欲求） 
● Action（購買） 
● Satisfaction（満足度） 
    
の頭文字を取ったもの。

👉ビジネスやマーケティングをする際は、この購買決定プロセスに沿って対策をすることで、効果的にお客さんを購買へ誘導できます。
    `,
  },
  freeStart: {
    EN: 'Start for free',
    JA: '無料で始める',
  },
  freeInstall: {
    EN: 'Install for free',
    JA: 'インストール',
  },
  copy: {
    EN: 'Optimize Internet and SNS advertising based on the AIDAS model',
    JA: 'AIDASモデルに基づいて SNS・ネット広告を最適化',
  },
  copyH3: {
    EN: 'Connect with and analyze Shopify、Yahoo Ads, Google Ads, Instagram and Facebook Ads, and Google Analytics',
    JA: 'Shopify、Yahoo広告、Google広告、Instagram・Facebook広告、Googleアナリティクスと連携し分析する',
  },
  contact: {
    EN: 'Contact',
    JA: 'ご連絡',
  },
  companyInfo: {
    EN: 'Company',
    JA: '会社概要',
  },
  companyInfo1: {
    EN: 'Company Name: 1F8',
    JA: '会社名：１F８',
  },
  companyInfo2: {
    EN: 'Established: June 2020',
    JA: '設立日: 2020年6月',
  },
  companyInfo3: {
    EN: 'JP Address: 4-29-4-315 Shinjuku, Tokyo 160-0023',
    JA: '日本住所: ４−２９−４−３１５新宿東京１６０−００２３',
  },
  companyInfo4: {
    EN: 'US Address: 20687 Amar Road Ste 2-900, Walnut, CA 91789',
    JA: '米国住所: 20687 Amar Road Ste 2-900, Walnut, CA 91789',
  },
  mission: {
    EN: 'Our Mission: Be the startup business go-to resource for IT development, enabling them to focus on the business',
    JA: 'ミッション: 新規企業のソフトウェア関連を一手に請け負うことで、顧客には自分のビジネスに集中もらうことを目的としています',
  },
  cookies: {
    EN: 'No cookies for you on this page!',
    JA: 'このホームページはCookieを利用していません！',
  },
  comingSoon: {
    EN: 'Coming Soon!',
    JA: '近日公開！',
  },
  learnMore: {
    EN: 'Find Out More',
    JA: '1F8について',
  },
  privacyPolicy: {
    EN: 'Privacy Policy',
    JA: 'プライバシーポリシー',
  },
  hiring: {
    EN: 'We\'re Hiring!',
    JA: '募集してます！',
  },
  ecommerce: {
    EN: 'ECommerce Development',
    JA: '',
  },
  slogan: {
    EN: 'Efficent, Effective, Secure Web Technology Development',
    JA: `私たちはWebテクノロジー開発を通して 
    お客様がビジネスに集中できる環境を創造します`,
  },
  contactName: {
    EN: 'Your Name',
    JA: 'お名前',
  },
  contactEmail: {
    EN: 'Your Email / Phone',
    JA: 'メール　・　電話電話',
  },
  contactHeading: {
    EN: 'Please let us know what we can help you with. Someone will respond to your inquiry within 1 business day.',
    JA: '何かお手伝いできることはありますか。私たちのチームにご興味のある方は是非ご連絡をください。24時間以内に必ず返信します。',
  },
  contactMessage: {
    EN: 'Message',
    JA: 'メッセジ',
  },
  contactSuccess: {
    EN: 'Thank You!',
    JA: 'ありがとうございます！',
  },
  contactFailed: {
    EN: 'Contact failed :( Please email hello@1f8.dev.',
    JA: 'やばい！ hello@1f8.devに直接メールをください',
  },
  website: {
    EN: 'Website Renewal',
    JA: 'ウェブサイトのリニューアル',
  },
  identify1: {
    EN: '1. Identify the problem',
    JA: '1. 問題を特定する',
  },
  whatsWrong: {
    EN: '(whats wrong? design? functionality? user experience? language?)',
    JA: '(デザイン? 機能? UX? 言語?)',
  },
  design2: {
    EN: '2. Design (2 weeks)',
    JA: '2. デザイン (2週間)',
  },
  programming3: {
    EN: '3. Programming (2 weeks)',
    JA: '3. プログラミング (2週間)',
  },
  maintenance4: {
    EN: '4. Maintenance (security / changes to content / etc)',
    JA: '4. メンテナンス (セキュリティ / コンテンツの更新 / etc)',
  },
  ourstrength: {
    EN: 'What is our strengths?',
    JA: '私達の強み',
  },
  bilingual1: {
    EN: '1. Internationalization Bilingual',
    JA: '1. 多言語化対応',
  },
  modern2: {
    EN: '2. Modern Design',
    JA: '2. モダンなデザイン',
  },
  responsive: {
    EN: '3. Support responsive',
    JA: '3. スマートフォン対応',
  },
  code3: {
    EN: '3. Focus on Code Quality / Maintainability',
    JA: '3. コードの質とメンテナンス性の優位性',
  },
  code: {
    EN: '4. Focus on Code Quality / Maintainability',
    JA: '4. コードの質とメンテナンス性の優位性',
  },
  projectButton: {
    EN: 'Our projects',
    JA: 'プロジェクト',
  },
  ecommerceTitle: {
    EN: 'ECOMMERCE',
    JA: 'Eコマース',
  },
  ecommerceSubTitle: {
    EN: 'CREATE A SHOPPING WEBSITE',
    JA: 'オンラインショップのサイト構築',
  },
  whatsEcommerce: {
    EN: 'Hey Whats Ecommerce?',
    JA: 'eコマースとは？',
  },
  webPrice: {
    EN: 'Price',
    JA: '料金',
  },
  works: {
    EN: 'Works',
    JA: '施工実績',
  },
  h3title1: {
    EN: 'Ishou Keikaku',
    JA: '意匠計画 様',
  },
  h3title2: {
    EN: 'Common Thread Collective',
    JA: 'Common Thread Collective 様',
  },
  h3title3: {
    EN: 'BeFound Online',
    JA: 'BeFound Online 様',
  },
  h3title4: {
    EN: 'Outdate Rx',
    JA: 'Outdate Rx 様',
  },
  h3title5: {
    EN: 'LineCrunch',
    JA: 'LineCrunch 様',
  },
  h3title6: {
    EN: 'Fresh Bowl',
    JA: 'Fresh Bowl 様',
  },
  h3title7: {
    EN: 'Handy & JRE',
    JA: 'Handy & JRE 様',
  },
  h3title8: {
    EN: 'Murakami Clinic',
    JA: '村上医院 様',
  },
  work1desc: {
    EN: `Business details: 
3DCG perspective production
    
    Project management system, 3D modeling, plug-in application development`,
    JA: `事業内容：3DCGパース制作
    
    プロジェクトマネージメントシステム、３Dモデリング、プラグインアプリ開発`,
  },
  work2desc: {
    EN: `Business details: 
    Entrepreneurship support
    
    Development of reporting tools such as Shopify, Google Adwords, Facebook ads`,
    JA: `事業内容：起業支援
    
    ShopifyやGoogle Adwords, Facebook広告などのレポートツール開発`,
  },
  work3desc: {
    EN: `Business details: Digital marketing

    Google My Business, Google Adwords, Facebook API Development`,
    JA: `事業内容：デジタルマーケティング
    Google My Business, Google Adwords, Facebook API開発`,
  },
  work4desc: {
    EN: `Business details: Logistic service
    
    Cloud migration and customer reporting portal`,
    JA: `事業内容：ロジスティックサービス
    クラウド移行および顧客レポートポータル`,
  },
  work5desc: {
    EN: `Business details: 
    Sports portal site management

    Dashboard, data scraping, API development`,
    JA: `事業内容：スポーツポータルサイト運営
    ダッシュボード、データスクレイピング、API開発`,
  },
  work6desc: {
    EN: `Business details: 
    Salad vending machine service

    Desktop application, third-party credit card processing function development
    `,
    JA: `事業内容：サラダの自販機サービス
    デスクトップアプリケーション、サードパーティのクレジットカード処理機能開発`,
  },
  work7desc: {
    EN: `Business details: Campaign

    Landing page design & optimization`,
    JA: `事業内容：キャンペーン
    ランディングページのデザイン＆最適化`,
  },
  work8desc: {
    EN: `Business details: Rebuilding of Clinic HP

    Homepage design, installation of coronavirus reservation form`,
    JA: `事業内容：クリ二ックHPの改修
    ホームページのデザイン、コロナウイルス予約フォームの設置`,
  },
  aboutPersonalInfo: {
    EN: `1f8 Co., Ltd. (hereinafter referred to as "our company") has established this privacy policy (hereinafter referred to as "this privacy policy") because it may acquire information about the individual of each user. .. This privacy policy applies when you use the Shopify app (hereinafter referred to as "the service") provided by our company, so please be sure to read it before using it.

    Scope of application
    This privacy policy applies to the Services provided by us.
    
    Information of users to be acquired and how to use it In this service, we will refer to the following personal information of users (hereinafter referred to as "users") (hereinafter referred to as "user information"). .) May be acquired and used.
    
    User information provided
    Information Required for Service Improvement: In order to improve quality, this service may obtain information such as cookies and web browser information, IP address information, information from the pages viewed, and device identification information.
    
    Inquiry information: We may obtain short messages and short emails, email addresses, phone numbers, voice messages, etc. for investigation, response, in-house training, identity verification, etc. to inquiries.
    
    Registration information for this service: Name (including company name / department or job name, etc.), address, telephone number, email address, demographics, device identification information, network activity, location data, user's favorite registration information , Analysis data, language setting, payment history, information necessary for using this service such as hosting service, site name, site URL.
    
    Purpose of use of user information
    We will use the acquired user information for the following purposes.
    
    Provision of user information to a third party
    In order to cooperate with affiliate program providers in this service, we may provide the following information to affiliate program providers (limited to affiliated programs in this service). "Items of data to be provided"
    
    Company name, name, department / title, phone number, email address, payment history, hosting service, address, site name, site URL
    Entrustment of user information
    We may outsource all or part of the user information obtained from users to outsourced companies (including outsourced companies in foreign countries) to the extent necessary to achieve the purpose of use. At that time, we will thoroughly examine the eligibility as a subcontractor, stipulate matters related to confidentiality in the contract, and establish a system to properly manage information.
    
    User rights
    The user can check and correct the user information on this service at any time. When requesting the Company to disclose personal information that cannot be confirmed on this service or to notify the purpose of use, the user can request disclosure, etc., except in the following cases, according to the procedure separately set by the Company.
    
    When disclosure may harm the life, body, property or other rights and interests of the person or a third party When disclosure may significantly hinder the proper implementation of our business If it is not possible to confirm that the request for disclosure is from the person himself / herself If the user determines that the content of the personal information held by the Company is not true as a result of the disclosure, the procedure separately determined by the Company You can request correction / addition / deletion of personal information according to the above. In that case, we will investigate without delay within the range necessary to achieve the purpose of use, and based on the result, we will correct, add or delete the personal information.
    
    If you are requested to disclose personal information that cannot be confirmed on this service, we may charge a disclosure fee according to the procedure separately set by our company.
    
    Revision of this privacy policy
    
    This privacy policy is subject to revision. Please note that any revisions will be made only on this privacy policy and will be effective from the stated revision date.
    
    inquiry
    
    If you have any questions regarding this privacy policy, or if you have any questions, complaints, or inquiries regarding the handling of user information in this service, please contact us at the following e-mail address.
    
    1F8 Co., Ltd.
    
    Email: hello@1f8.dev
    
    Address: 4-29-4-315 Nishi-Shinjuku, Shinjuku-ku, Tokyo
    
    Enacted and enforced on June 2, 2021 `,
    JA: `株式会社1f8（以下「当社」といいます。）は、利用者の皆様の個人に関する情報を取得することがありますので、このプライバシーポリシー（以下「本プライバシーポリシー」といいます。）を定めています。当社が提供するShopifyアプリ（以下「本サービス」といいます。）をご利用の際には、本プライバシーポリシーが適用されますので、ご利用の前に、必ずお読みください

    適用範囲
    本プライバシーポリシーは、当社の提供する本サービスに関して適用されます。
    
    取得する利用者の皆様の情報及び利用方法 当社は、本サービスにおいて次のように利用者の皆様（以下「利用者」といいます。）の個人に関する情報（以下「利用者情報」といいます。）を取得及び利用する場合があります。
    
    ご提供いただく利用者情報
    サービス改善に必要な情報: 本サービスでは品質改善のために、Cookieおよびウェブブラウザー情報、IPアドレス情報、閲覧したページから情報、デバイスの識別情報を取得する場合がございます
    
    お問い合わせに係る情報: お問い合わせに対する調査、返答、社内トレーニング、及びご本人確認等のためにショートメッセージ及びショートメール、メールアドレス、電話番号、ボイスメッセージなどを取得する場合がございます
    
    本サービスへの登録情報: 氏名（企業名・部署又は役職名等含みます。）、住所、電話番号、メールアドレス、人口統計、デバイスの識別情報、ネットワークアクティビティー、位置データ、利用者のお気に入り登録情報、分析データ、言語設定、支払い履歴、ホスティングサービス等の本サービス、サイト名、サイトURLをご利用頂く上で必要な情報。
    
    利用者情報の利用目的
    当社は取得した利用者情報を以下の目的で利用いたします。
    
    利用者情報の第三者提供
    当社は、本サービスにおいてアフィリエイトプログラム業者と連携するため、以下の情報について、アフィリエイトプログラム提供者（本サービスにおける連携先に限られます。）に対し、提供する場合があります。 「提供するデータの項目」
    
    企業名、氏名、部署・役職名、電話番号、メールアドレス、支払い履歴、ホスティングサービス、住所、サイト名、サイトURL
    利用者情報の委託
    当社は利用目的の達成に必要な範囲内において利用者から取得した利用者情報の全部または一部を業務委託先（外国にある委託先を含みます。）に委託することがございます。その際、業務委託先としての適格性を十分審査するとともに、契約にあたって守秘義務に関する事項等を規定し、情報が適正に管理される体制作りを行います。
    
    利用者の権利
    利用者はいつでも利用者情報を、本サービス上で確認、訂正することができます。利用者は当社に対し、本サービス上で確認できない個人情報の開示や利用目的の通知を求める場合、当社が別途定めた手続きに従って、次の場合を除き開示等を請求することができます。
    
    開示することで本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合 開示することで当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合 開示することが法令に違反することとなる場合 開示の請求がご本人からであることが確認できない場合 開示の結果、当社保有の個人情報の内容が事実でないと利用者が判断した場合は、当社が別途定めた手続きに従って、個人情報の訂正・追加・削除を請求することができます。その場合、当社は利用目的の達成に必要な範囲内で遅滞なく調査を行い、その結果に基づき当該個人情報の訂正・追加・削除を行います。
    
    なお、本サービス上で確認できない個人情報の開示を請求される場合には、当社が別途定めた手続きに従って開示手数料をいただく場合がございます。
    
    本プライバシーポリシーの改定
    
    本プライバシーポリシーは改定されることがあります。改定される場合は本プライバシーポリシー上でのみの告知となり、記載されている改定日から有効となりますのであらかじめご了承下さい。
    
    お問い合わせ
    
    本プライバシーポリシーに関してご不明な点がある場合、本サービスにおける利用者情報の取り扱いに関するご質問・苦情・ご相談等があります場合は以下のメールアドレスにご連絡ください。
    
    株式会社1F8
    
    Email：hello@1f8.dev
    
    住所: 東京都新宿区西新宿四丁目29番4-315号
    
    2021年6月2日制定・施行`,
  },
  contactformDesc: {
    EN: `Inquiry

    For inquiries about our services, please click here.`,
    JA: `お問い合わせ
    
    弊社のサービスに関するお問い合わせはこちらからお願いします。`,
  },
  companyName: {
    EN: 'Company name：',
    JA: '会社名：',
  },
  name: {
    EN: 'Name：',
    JA: '氏名：',
  },
  email1: {
    EN: 'Email address：',
    JA: 'メールアドレス：',
  },
  email2: {
    EN: 'Email address (for confirmation):',
    JA: 'メールアドレス（確認用）：',
  },
  phoneNumber: {
    EN: 'Telephone number：',
    JA: '電話番号：',
  },
  websiteOptional: {
    EN: 'Website (optional)：',
    JA: 'ウェブサイト（任意）：',
  },
  contentSelections: {
    EN: 'Content of consultation：',
    JA: 'ご相談内容：',
  },
  contactMessages: {
    EN: 'Messages：',
    JA: 'メッセージ：',
  },
  personalInfo: {
    EN: 'About the handling of personal information',
    JA: '個人情報の取扱いについて',
  },
  agreement: {
    EN: 'Agreement',
    JA: '同意する',
  },
  send: {
    EN: 'Send',
    JA: '送信する',
  },
  basicPrice: {
    EN: 'Website production basic design cost  :  150,000yen〜',
    JA: 'WEBサイト制作基本設計費用  :  150,000円〜',
  },
  codingPrice: {
    EN: 'Design & Coding cost  :  50,000yen〜/page',
    JA: 'デザイン・コーディング  :  50,000円〜/ページ',
  },
  otherPrice: {
    EN: 'Others  :  Separate fee',
    JA: 'その他  :  別途お見積り',
  },
  defineEcommerce: {
    EN: 'Ecommerce is about selling products and services online. We prefer Shopify platform to create your ecommerce website so that money transactions are securely done over the internet, but if you are inclined to use other platforms, please let us know. We will work with you.',
    JA: 'Eコマースとは何らかの商品やサービスをオンライン上で売買することです。弊社では、ネット上での金銭取引の安全面を考えて、Shopify（ショッピファイ）のプラットフォームで主な開発を進めていますが、他のプラットフォームでのご用件がありましたら、お気軽にご相談ください。',
  },
  ecommerceImgAlt: {
    EN: 'Online store',
    JA: 'オンラインストア',
  },
  identifyProductsTitle: {
    EN: 'Identify what you want to sell online',
    JA: 'ネット販売してみたい商品を考える',
  },
  identifyProductsDescription: {
    EN: 'Are you excited about clothing or high-end camping gears? Whatever your interests are, we can help you with it. Identify what you want to sell in your oneline store. Share those ideas with us, and we will help you mold your ideas into reality in the next step!',
    JA: '最近流行りの洋服やグランピング商品などを売ってみたいと思ったことはありますか？何をネット上で売ってみたいかの想像を膨らませて弊社と共にそれを実現できるように仕向けてみませんか？きっとその夢の実現のお手伝いができると思います！',
  },
  createWebsiteImgAlt: {
    EN: 'computer with cogs',
    JA: 'コンピューターと歯車',
  },
  createWebsiteTitle: {
    EN: 'Create website (Shopify App) (approximately 2 weeks)',
    JA: 'ウェブサイトを構築（ショッピファイでおよそ２週間）',
  },
  createWebsiteDescription: {
    EN: 'This is when things become exciting and real. Let us do the heavy lifting, and you will be able to start selling products approximately in two weeks! Once we are done building your online shop, we will help you get accustomed to navigating around the site if necessary. Congratulations! You are a onlien store owner!',
    JA: 'ここからが本番！弊社にウェブサイトの構築を全てお任せください。ショッピファイのサイトであればおよそ２週間ほどで完成します。ウェブサイトの設立後、少し不安だという方には、サイト内の各ページの基本的な操作方法などのご質問にご説明、お答えいたします。この時点でついにEコマースのショップオーナーです！',
  },
  generateTrafficTitle: {
    EN: 'Generate traffic',
    JA: '集客数を増やす',
  },
  generateTrafficDescription: {
    EN: 'No customers, yet? No problem! We have an cutting edge app you can plugin your online store to, to help you see the customer traffic. Are you using Shopify, TikTok, Snpachat or Facebook to advertize your store? We can help you pivot your marketing strategies based on those collected real time data.',
    JA: '夢にまでみたウェブサイトのオーナーとなったわけですが、どうも集客数が上がらないとお困りですか？弊社はそういった問題を抱えているウェブサイトのオーナー様の為に、最先端の技術を要したアプリを開発いたしました。そのアプリを使いデータをご使用中のショッピファイ、TikTok、スナップチャット、Facebookなどの集客情報をリアルタイムで分析することが可能になります。',
  },
  whatsDropshippingImgAlt: {
    EN: 'online store with products images',
    JA: 'オンラインストアと商品の写真',
  },
  whatsDropshipping: {
    EN: 'Hey Whats Dropshipping?',
    JA: 'ドロップシッピングとは？',
  },
  defineDropshipping: {
    EN: 'Dropshipping is a service where someone who wants to start an online store can simply build one and "drop" any products of your choice from the dropshipping app into your own store and sell those items. Deliveries and shippnig of these prducts you sell would be done by the owner of each products\' businesse.',
    JA: 'ドロップシッピングとは、オンラインストアを開設したいが何を売ったら良いのかわからない人や売るための商品を供給してくれるスプライヤーがいない場合に、オンラインストアに出品するための商品を提供してくれるアプリです。オンラインストアを開設しドロップシッピングアプリをダウンロードするだけであなたもオンラインストアのオーナーになれるわけです。商品の梱包出荷などは個々の商品提供元が行います。',
  },
  identifyTitle: {
    EN: '',
    JA: '',
  },
  identifyDescription: {
    EN: 'Click the title go to previous section.',
    JA: 'タイトルをクリックして前記をご覧ください。',
  },
  useOurDropshipAppTitle: {
    EN: 'Use our Shopify Dropshipping App to get listeds on websites',
    JA: 'ショッピファイドロップシッピングアプリから商品を閲覧、販売',
  },
  useOurDropshipAppDescription: {
    EN: 'Our Shopify Dropshipping App has thousands of products you can choose from. The fastest way to get started is to decide what category of items you want to sell at your online store and select products from that category.',
    JA: '自社開発したショッピファイドロップシッピングアプリには何千もの商品が掲載されていますので、何を売って良いかわからないという方は自分のオンラインストアで売りたいカテゴリーを選択し、その中から商品を掲載する事をお勧めします。',
  },
  learnManageInventoryImgAlt: {
    EN: 'online store with products images',
    JA: 'オンラインストアと商品の写真',
  },
  learnManageInventoryTitle: {
    EN: 'Learn how to manage online inventory',
    JA: 'オンラインストアの在庫管理',
  },
  learnManageInventoryDescription: {
    EN: 'It is important not to run out of stock or inventory counts from your oneline store. Maybe you have another business or you have so many products and don\'t have time to check your stock? No problem. We created Shopify Stock Concierge App which watches your products\' inventory levels and help you keep invenotry counts up to date. When those inventory counts are at or below the threshold you set them at, it will notify you via email, and you can update the counts from the link we send!',
    JA: 'オンラインストアで重要なのは商品の在庫管理です。副業としてオンラインショッピングを始めたい方や品数が多く在庫管理が大変だと思います。そんな方のために自社開発したショッピファイストックコンシェルジュの在庫管理アプリを使ってみませんか？商品ごとに在庫通知のしきい値を設定し、在庫数がしきい値又はそれ以下になった場合にメールでお知らせが来ます。メールには在庫管理ページに繋がるリンクが添付されていますのでそこから直接在庫の値を簡単に変えることができます！',
  },
  makeMoneyTitle: {
    EN: 'Make money',
    JA: 'ネットショップで稼ぐ',
  },
  makeMoneyDescription: {
    EN: 'We securely handle all the payments in the Shopify platform. All you have to do is to manage your inventory counts and market your online store, and we will give you your share weekly directly to your account. You need an assistant for managing your inventory counts? Please check out our Stock Concierge App. If you are interested in improving your stire traffic, please check out Statlas App.',
    JA: 'ドロップシッピングアプリご利用のオンラインストアのオーナー様には、弊社からオーナー様の取り分の金額を毎週ショッピファイのプラットフォーム上で安全にお支払い致します。ご自身でやっていただくことはオンラインストア在庫管理とマーケティングのみです。在庫管理のお手伝いが必要なら自社開発のストックコンシェルジュアプリ、マーケティングのお手伝いが必要な方はスタットラスアプリをぜひご利用ください。',
  },
  projectsTitle: {
    EN: 'Projects',
    JA: 'プロジェクト',
  },
  projectStockConcierge: {
    EN: 'Shopify Stock Concierge - Inventory management tool',
    JA: 'ショッピファイ ストックコンシェルジュ アプリ - 在庫管理ツール',
  },
  projectStatlas: {
    EN: 'Statlas - Data analizying tool',
    JA: 'スタットラス - データ分析ツール',
  },
  welcomePageTitle: {
    EN: 'Welcome Page',
    JA: 'ようこそページ',
  },
  welcomePageAlt: {
    EN: 'Welcome page',
    JA: 'ようこそページ',
  },
  setupPageTitle: {
    EN: 'Setup page - add emails',
    JA: '設定ページ　-　メールアドレスの追加',
  },
  setupPageAlt: {
    EN: 'Add emails on setup page',
    JA: '設定ページでメールアドレスの追加ができます',
  },
  alertsSetupTitle: {
    EN: 'Alert setup view',
    JA: '',
  },
  alertsSetupAlt: {
    EN: 'Alert setup view with list of inventory variants',
    JA: '',
  },
  loginPageTitle: {
    EN: 'Statlas Login Page',
    JA: '',
  },
  loginPageAlt: {
    EN: 'Statlas login page with an example account name',
    JA: '',
  },
  newsDesc: {
    EN: 'About Overseas Web marketing・Business improvement',
    JA: '海外Webマーケティング・業務改善に関するニュース',
  },
  serviceDesc: {
    EN: 'About Services',
    JA: 'サービス内容',
  },
  news1: {
    EN: 'Interested in Digital Transformation? We\'re giving away 3 Free DX transformations. Contact us!',
    JA: `デジタルトランスレーション（DX）に興味はありませんか？
    只今３名様限定で導入費用無料でShopifyサイトをプレゼントしますのでご連絡ください！`,
  },
  news2: {
    EN: 'We\'re offering free consultation on integrating the latest web/cloud technologies',
    JA: '最新のWEBツールを導入して業務効率化。無料相談の受付を開始しました。',
  },
  news3: {
    EN: 'Consult with us on giving your old website some life',
    JA: '思うような成果が上がりませんか？WEBサイトの改善点を無料で診断します。',
  },
  service1: {
    EN: 'Build your Shopify online store. We can help you get started and then when you\'re going, we provide expert Shopify business services to go beyond what Shopify offers.',
    JA: `オンラインショップのサイトを構築します。安全性の面から主にShopifyのプラットフォームで開発しておりますが、他のプラットフォームでのご用件もお気軽にご相談ください。

    また、オンラインショップの新しい形として世界で注目されている「ドロップシッピング」を簡単に始められる、自社開発のアプリをご用意。パートナー様を募集しております。`,
  },
  service2: {
    EN: 'Site Renewal! Is your site looking old and you\'re still paying too much for it? We offer free hosting on our site redesigns. Read about our succes stories and then contact us for free consultation.',
    JA: 'Webサイトの改善点を洗い出してのリニューアルや、新規制作を承ります。多言語化対応、コードの質とメンテナンス性、スマートフォン対応、モダンなデザインが強みです。',
  },
  service3: {
    EN: 'We\'re experts in web technology for small businesses so we have tools that make applications for your small business much more affordable. Let\'s discuss!',
    JA: '各種マーケティングツールや業務ツール、社内外向けのWebアプリケーションの開発をしております。新規な事業形態にも対応可能ですので、お気軽にご相談ください。',
  },
  serviceTitle1: {
    EN: 'ECommerce development',
    JA: 'E-コマース開発',
  },
  serviceTitle2: {
    EN: 'Website development',
    JA: 'Webサイト制作',
  },
  serviceTitle3: {
    EN: 'Business system / application development',
    JA: '業務システム・アプリ開発',
  },
  button1: {
    EN: 'More details →',
    JA: 'さらに詳しく　→',
  },
  button2: {
    EN: 'Free consultation　→',
    JA: '無料相談する　→',
  },
  button3: {
    EN: '',
    JA: '',
  },
  dropShippingTitle: {
    EN: 'For those thinking of selling products overseas',
    JA: '海外での販売をお考えの方へ',
  },
  dropShippingDesc1: {
    EN: 'Have you...',
    JA: '突然ですが、あなたは',
  },
  dropShippingDesc2: {
    EN: 'Heard of...',
    JA: 'という言葉を聞いたことがありますか？',
  },
  dropShippingDesc3: {
    EN: 'Products made in Japan are known for their quality and craftsmanship. However, many Japanese product manufacturers are still selling only locally, and there are many small and medium-sized enterprises that have not expanded online. Even in online shopping, purchasing products made in Japan is increasing more than in the past, but finding an easy-to-use online shop is a difficult task.',
    JA: '日本製の商品は品質が良いことで知られています。しかし、まだ沢山の日本製品の製造元は地元または国内のみでの販売にとどまっており、ネットでの進出をしていない中小企業が多々あります。オンラインショッピングでも日本製の商品を購入するのは昔よりは増加傾向にあるものの、使いやすいオンラインショップを探すのは至難の業です。',
  },
  dropShippingDesc4: {
    EN: 'Reach out to the world-wide market',
    JA: 'そこで、世界的に注目されているのが',
  },
  dropShippingDesc5: {
    EN: 'Is such as service. Dropshipping is another way to help manufacturers not have to pay time and money to open an online shop and attract customers.',
    JA: 'というサービスになります。ドロップシッピングは、製造者がオンラインショップの開設と集客に時間とコストを支払わなくて良いように、他の',
  },
  dropShippingDesc6: {
    EN: 'mechanism',
    JA: '仕組みです。',
  },
  dropShippingDesc7: {
    EN: 'Manufacturers manufacture products, manage inventory with the app, and wait for the products to be purchased. When an item is purchased, the platform will deposit the sales by shipping the item to the designated location.',
    JA: '製造元の方々は商品を製造し、アプリで在庫を管理して商品が購入されるのを待ちます。商品が購入されると、指定された場所に商品を発送することで、プラットフォームから売上が入金されます。',
  },
  dropShippingDesc8: {
    EN: 'Online store operators, it is possible to sell Japanese-made products without the ships to produce their own products, manufacturer and online store operators will be the win-win relationship.',
    JA: 'オンラインストア運営者は、自ら商品を生産して発送をせずに日本製の商品を販売することができるので、製造者とオンラインストア運営者はwin-winな関係となります。よって、',
  },
  dropShippingDesc9: {
    EN: 'Its possible!',
    JA: 'ことができるのです。',
  },
  dropShippingDesc10: {
    EN: 'And we at 1F8 are creating a small business ECShop and dropshipping platform and furthermore, community.',
    JA: 'そして、私達1F8はドロップシッピングプラットフォーム（アプリ）を開発し、国内外からの需要と供給に応えられるようにしようと試みています。',
  },
  dropShippingDesc11: {
    EN: 'Why not contact us to discuss the opportunities?',
    JA: 'ぜひ、この機会に弊社のパートナーとしてご参加しませんか？',
  },
  dropShippingText: {
    EN: 'Dropshipping',
    JA: 'ドロップシッピング',
  },
  dropShippingText2: {
    EN: 'Ask a 3rd party online shop to sell',
    JA: '第三者のオンラインショップに自社の製品を売ってもらう',
  },
  dropShippingText3: {
    EN: 'Manufacturers generate profits without managing or marketing online stores',
    JA: '製造者はオンラインストアの管理やマーケティングをすることなく利益を生み出す',
  },
  footerCompany: {
    EN: 'Company',
    JA: '会社名',
  },
  footerSetupDate: {
    EN: 'Established 2020',
    JA: '設立 / 2020年6月',
  },
  footerAdress: {
    EN: 'Address: Shinjuku, Tokyo',
    JA: '住所 / 〒160-0023 東京都新宿区西新宿4-29-4-315',
  },
  footerWebsite: {
    EN: 'Website',
    JA: 'ウェブサイト',
  },
  headerContact: {
    EN: 'Contact',
    JA: 'お問い合わせ',
  },
  // setupPageTitle: {
  //   EN: '',
  //   JA: ''
  // },
  // setupPageAlt: {
  //   EN: '',
  //   JA: ''
  // },
  // alertsSetupTitle: {
  //   EN: '',
  //   JA: ''
  // },
  // alertsSetupAlt: {
  //   EN: '',
  //   JA: ''
  // },
}

export default Messages
