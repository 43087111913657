import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import TranslateText from 'components/TranslateText'
import useLang from 'lib/hooks/useLang'
import Messages from 'translations/Message'
import { Circle, Div } from 'components/ui'


const ChoiceComponent = () => {

  const { lang } = useLang()

  return (
    <Div id='choice'>
      <div className='bg-shapes bg-cover'>
        <div className='py-20 flex-col justify-center items-center text-center relative'>
          <h2 className='text-black-melody ml-2 font-bold text-3xl sm:text-4xl py-10'>
            <TranslateText lang={lang} msg={Messages.choice} />
          </h2>
          <p className='z-20 absolute left-0 right-0 py-10 px-20 font-bold sm:text-2xl'>
            <TranslateText lang={lang} msg={Messages.choiceDesc} />
          </p>
          <div className='absolute left-0 right-0 top-30 z-0'>
            <StaticImage src='../../images/hp/question-mark.svg'
              alt='question mark'
              placeholder='blurred'
              width={100}
              height={149} />
          </div>
          <p className='pt-80 font-bold sm:text-3xl lg:text-4xl'>
            <TranslateText lang={lang} msg={Messages.feature} />
          </p>
          <div className='flex justify-center gap-10 py-16 flex-col md:flex-row items-center lg:gap-24'>
            <Circle>
              <TranslateText lang={lang} msg={Messages.featureDesc1} />
            </Circle>
            <Circle>
              <TranslateText lang={lang} msg={Messages.featureDesc2} />
            </Circle>
            <Circle>
              <TranslateText lang={lang} msg={Messages.featureDesc3} />
            </Circle>
          </div>
        </div>
      </div>
    </Div>
  )
}

export default ChoiceComponent
