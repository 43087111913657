import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import TranslateText from 'components/TranslateText'
import useLang from 'lib/hooks/useLang'
import Messages from 'translations/Message'
import { Div } from 'components/ui'
import HpCard from './HpCard'


const AboutAppComponent = () => {

  const { lang } = useLang()

  return (
    <Div
      className='bg-gray-200 text-center px-6 pt-12 pb-10'
      lgScreen='px-0 py-28'
    >
      <div className='md:max-w-5xl lg:max-w-screen-md mx-auto'>
        <h2 className='px-10 lg:pb-6 text-3xl sm:text-5xl font-bold'><TranslateText lang={lang} msg={Messages.aboutAppTitle} /></h2>
        {/* About function1 */}
        <HpCard
          lang={lang}
          functionTitle={Messages.functionality.title1}
          functionDesc={Messages.functionality.desc1}
          imgPosition='right'>
          <StaticImage src='../../images/hp/aboutMockup1.svg'
            alt='About app function1'
            placeholder='blurred'
            width={320}
            height={290} />
        </HpCard>
        {/* About function2 */}
        <HpCard
          lang={lang}
          functionTitle={Messages.functionality.title2}
          functionDesc={Messages.functionality.desc2}
          imgPosition='left'>
          <StaticImage src='../../images/hp/aboutMockup2.svg'
            alt='About app function2'
            placeholder='blurred'
            width={500}
            height={280} />
        </HpCard>
        {/* About function3 */}
        <HpCard
          lang={lang}
          functionTitle={Messages.functionality.title3}
          functionDesc={Messages.functionality.desc3}
          imgPosition='right'>
          <StaticImage src='../../images/hp/aboutMockup3.svg'
            alt='About app function3'
            placeholder='blurred'
            width={315}
            height={305} />
        </HpCard>
      </div>
    </Div>
  )
}

export default AboutAppComponent
