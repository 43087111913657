import * as React from 'react'

const HpButtonComponent = ({ children, onClick=()=>{}, className='' }) => {
  const formClass = () => {
    return [
      'text-base py-3 px-10 bg-purple-button text-white font-bold rounded-xl shadow-md',
      'md:text-lg md:py-2',
      'lg:text-2xl lg:py-4',
      'hover:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-opacity-75',
      className,
    ].join(' ')
  }

  return (
    <button type='button' className={formClass()} onClick={onClick}>
      {children}
    </button>
  )
}

export default HpButtonComponent
